import React, { useState, useEffect } from 'react';
import { decode } from 'js-base64';
import { API_RESPONSE } from 'api/_const';
import HeaderBar from 'components/header-bar';
import InputTextField from 'components/input-text';
import BottomSheet from 'components/bottom-sheet';
import Button from 'components/button';
import AddressSelector from 'components/address-selector';
import DateField from 'components/date-picker';

import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { useAPI } from 'utils/api';
import { showLoading, dismissLoading } from 'utils/loading';

const MemberInfo = () => {
  const api = useAPI();

  const [memberProfile, setMemberProfile] = useState({
    mobile: '',
    name: '',
    birthday: '',
    twid: '',
    email: '',
    cityId: '',
    districtId: '',
    address: '',
    printflag: 0,
    detailflag: 0,
  });
  const [inputUpdated, setInputUpdated] = useState(false);
  const [showTWIDValid, setShowTWIDValid] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendMemberData') {
      const dataJsonString = decode(dataJson.params.data);
      const jsonData = JSON.parse(dataJsonString);
      setMemberProfile((state) => ({
        ...state,
        ...jsonData,
        cityId: jsonData.city,
        districtId: jsonData.district,
      }));
      setEmail(jsonData.email);
      setName(jsonData.name);
      setAddress(jsonData.address);
      setInputUpdated(true);
    }
  };

  const verifyId = (id) => {
    if (id.length <= 0) {
      return true;
    }
    var city = new Array(
      1,
      10,
      19,
      28,
      37,
      46,
      55,
      64,
      39,
      73,
      82,
      2,
      11,
      20,
      48,
      29,
      38,
      47,
      56,
      65,
      74,
      83,
      21,
      3,
      12,
      30
    );
    id = id.toUpperCase();
    // 使用「正規表達式」檢驗格式
    if (id.search(/^[a-z](1|2)\d{8}$/i) === -1) {
      return false;
    } else {
      //將字串分割為陣列(IE必需這麼做才不會出錯)
      id = [...id];
      //計算總分
      var total = city[id[0].codePointAt(0) - 65];
      for (var i = 1; i <= 8; i++) {
        total += Number(id[i]) * (9 - i);
      }
      //補上檢查碼(最後一碼)
      total += Number(id[9]);
      //檢查比對碼(餘數應為0);
      return total % 10 === 0;
    }
  };

  const validData = (param) => {
    if (param.email === '') {
      alert('請輸入Email');
      return false;
    }
    if (param.address === '') {
      alert('請輸入地址');
      return false;
    }
    return true;
  };

  const maskName = (name) => {
    if (name !== undefined && name.length > 0) {
      return `${name.substring(0, 1)}` + '*' + `${name.substring(1 + 1)}`;
    }
    return '';
  };

  const maskMobile = (mobile) => {
    if (mobile !== undefined && mobile.length > 0) {
      return (
        `${mobile.substring(0, 3)}` + '****' + `${mobile.substring(3 + 4)}`
      );
    }
    return '';
  };

  const maskTwId = (twId) => {
    if (twId !== undefined && twId.length > 0) {
      return `${twId.substring(0, 3)}` + '****' + `${twId.substring(3 + 4)}`;
    }
    return '';
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const param = {
      name,
      birthday: memberProfile.birthday,
      twid: memberProfile.twid,
      email,
      cityId: memberProfile.cityId,
      districtId: memberProfile.districtId,
      address,
      printflag: memberProfile.printflag,
      detailflag: memberProfile.detailflag,
    };
    if (!validData(param)) return;
    showLoading();
    api
      .updateMemberProfile(param)
      .then((res) => {
        dismissLoading();
        if (res.code !== API_RESPONSE.SUCCESS) {
          alert(res.reason);
          return;
        }
        alert('資料更新成功', () => {
          callAppFunc('backHome', {});
        });
      })
      .catch(() => {
        dismissLoading();
      });
  };

  const handleGoBack = () => {
    callAppFunc('backHome', {});
  };

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchMemberData', {});
  }, []);

  useEffect(() => {
    return () => {
      setInputUpdated(false);
    };
  }, [inputUpdated]);

  return (
    <>
      <HeaderBar title="會員資料維護" goBack={handleGoBack} />
      <form onSubmit={handleSubmit}>
        {!inputUpdated && (
          <div className="member-form">
            <InputTextField
              label="帳號"
              marginBottom={12}
              name="account"
              text={maskMobile(memberProfile.mobile)}
              disable
            />
            <InputTextField
              label="姓名"
              name="name"
              marginBottom={12}
              text={maskName(name)}
              disable={name !== ''}
            />
            <DateField
              label="生日"
              name="birthday"
              format="****-MM-dd" // default: yyyy-MM-dd
              marginBottom={12}
              dateValue={
                memberProfile.birthday
                  ? new Date(memberProfile.birthday)
                  : new Date()
              } // Type: JS Date object
              onDateChange={(newDate) => console.log(newDate)} // typeof newDate: JS Date Object
              disable={memberProfile.birthday !== ''}
            />
            <InputTextField
              label="E-mail"
              marginBottom={12}
              name="email"
              text={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <AddressSelector
              cityId={memberProfile.cityId}
              districtId={memberProfile.districtId}
              citySelect={(id) =>
                setMemberProfile((state) => ({ ...state, cityId: id }))
              }
              districtSelect={(id) =>
                setMemberProfile((state) => ({ ...state, districtId: id }))
              }
            />
            <InputTextField
              label=""
              placeholder="地址"
              text={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        )}
        <BottomSheet persistant noTitle noRounded>
          <Button text="修改" type="submit" />
        </BottomSheet>
      </form>
      <style jsx>
        {`
          .member-form {
            padding: 24px 20px;
            background-color: #f2f2f2;
            padding-bottom: 120px;
          }
        `}
      </style>
    </>
  );
};

export default MemberInfo;
